
import { computed, defineComponent, nextTick, onMounted, onUnmounted } from "vue";
import GuestLayout from "@/layout/GuestLayout.vue";
import MobileTheoryBlock from "@/views/Onboarding/MobileTheoryBlock.vue";
import SchoolLogo from "@/components/Avatars/SchoolLogo.vue";
import { useRoute, useRouter } from "vue-router";
import TrainingBlockHeader from "@/views/BasicUser/TrainingBlockHeader.vue";
import SocialLinks from "@/components/SocialLinks.vue";
import useDrivingSchoolStore from "@/composable/useDrivingSchoolStore";

export default defineComponent({
  name: "WellDone",
  components: { SocialLinks, TrainingBlockHeader, SchoolLogo, MobileTheoryBlock, GuestLayout },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { links, getLinks } = useDrivingSchoolStore();

    const drivingSchoolId = computed(() => Number(route.params.drivingSchool));

    onMounted(() => {
      nextTick(() => {
        const huraEl = document.getElementById("hura");
        huraEl?.classList.add("registration-success-img-wrapper");
      });
      getLinks(drivingSchoolId.value);
    });

    onUnmounted(() => {
      const huraEl = document.getElementById("hura");
      huraEl?.classList.remove("registration-success-img-wrapper");
    });

    const onClickAnotherRequest = () => {
      router.push({ name: "BasicDashboard" });
    };

    const socialLinks = computed<Array<any>>(() => links.value?.socialLinks || []);

    const onboardingLinks = computed<Array<any>>(() => links.value?.onboardingLinks || []);

    const onRedirect = (link: string) => {
      window.open(link, "_blank");
    };

    return {
      drivingSchoolId,
      onClickAnotherRequest,
      socialLinks,
      links,
      onboardingLinks,
      onRedirect,
    };
  },
});
