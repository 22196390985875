
import { computed, defineComponent, PropType } from "vue";
import { ISocialLinkDto } from "@/interfaces/ISocialLinkDto";
import { SocialLinkEnum } from "@/enums/SocialLinkEnum";

export default defineComponent({
  name: "SocialLinks",
  props: {
    links: {
      type: Array as PropType<Array<ISocialLinkDto>>,
      default: () => [],
    },
  },

  setup(props) {
    const getIcon = (type: SocialLinkEnum) => {
      switch (type) {
        case SocialLinkEnum.facebook:
          return "fa-facebook-f";
        case SocialLinkEnum.google:
          return "fa-google";
        case SocialLinkEnum.instagram:
          return "fa-instagram";
        case SocialLinkEnum.youtube:
          return "fa-youtube";
        default:
          return "";
      }
    };

    const customLinks = computed<Array<any>>(() => {
      return props.links?.map((link: ISocialLinkDto) => {
        return {
          ...link,
          icon: getIcon(link.title.toLowerCase() as SocialLinkEnum),
        };
      });
    });
    return {
      customLinks,
    };
  },
});
