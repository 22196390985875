
import { Options, Vue } from "vue-property-decorator";
import { namespace } from "s-vuex-class";

const DrivingSchoolModule = namespace("driving-school");

@Options({
  components: {},
})
export default class TrainingBlockHeader extends Vue {
  public name = "TrainingBlockHeader";

  @DrivingSchoolModule.Action("findAll")
  public findStudentRegistrations: any;

  @DrivingSchoolModule.Getter("getDataList")
  public studentRegistrations: any;

  public mounted(): void {
    this.findStudentRegistrations({
      resource: "registrations/registration-for-student",
    });
  }

  private get additionalTile() {
    return Boolean(this.studentRegistrations?.length);
  }
}
